$primary: #056D6C;
$light: #fff;

//Background
$background-light: $light;
$background-dark: $primary;
$background-body: #FEFEFE;
$background-blur: rgba(255, 255, 255, 0.8);

//Button
$button-main: $primary;
$button-hover: #D7E7E7;
$button-disabled: #69A7A7;
$button-background: $light;

$button-hover-plans: #2A8382;
$button-click-plans: #086160;

//Text
$text-primary: #2A3F3F;
$text-secondary: #607575;
$text-light: #999;

$text-subtitle: #435757;
$text-description: #5F7474;
$text-contrast: #9BC5C4;

//Inputs
$border-input: #E8E8E8;
$input-text: #2A3F3E;
$placeholder: #949E9E;
$input-hover: #EBF2F1;
$input-focus: #0F4D4D;
$input-error: #C96073;
$select-bg: #ecf2f2;

//Misc
$divider: #E8E8E8;
$divider-plans: #E0ECEC;
$divider-switcher: #1E7B7A;

$gradient-start: rgba(4, 73, 73, 0);
$gradient-end: rgba(4, 73, 73, 0.15);

$shadow: rgba(2, 54, 54, 0.12);