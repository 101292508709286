@import '../colors';

.plans {
  h2 {
    margin-bottom: 40px;
  }

  .container {
    display: flex;
    justify-content: center;
    gap: 40px;

    .plan__item {
      display: flex;
      flex-direction: column;
      flex: 1 1 33%;
      background-color: $light;
      border-radius: 12px;
      text-align: left;
      box-shadow: 0 0 50px 0 $shadow;
      min-width: 360px;
      overflow: hidden;

      .plan__item-header {
        display: flex;

        h3 {
          padding: 24px 0 0 32px;
          font-size: 24px;
          color: $text-primary;
        }
      }

      .plan__item-amount {
        padding: 20px 0;
        margin: 0 32px;
        color: $primary;
        display: flex;
        align-items: baseline;
        border-bottom: 1px solid $divider-plans;

        .plan__item-amount-value {
          font-size: 64px;
          font-weight: 700;
        }

        .plan__item-amount-period {
          font-size: 36px;
          font-weight: 600;
          opacity: 60%;
        }
      }

      ul {
        list-style-image: url('../../img/icons/dot.svg');
        padding: 20px 0;
        margin: 0 32px 0 47px;
        flex-grow: 2;

        li {
          font-size: 18px;
          color: $text-secondary;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .plan__btn {
        padding: 24px 32px;
        background-color: $button-main;
        border-radius: 0 0 10px 10px;

        .plan__btn-link {
          display: flex;
          justify-content: space-between;
          color: $light;
          text-decoration: none;
          font-size: 24px;

          .btn-arrow {
            transition: 0.2s ease;
            transform: rotate(-45deg);
          }
        }

        &:hover {
          background-color: $button-hover-plans;

          .plan__btn-link .btn-arrow {
            transform: rotate(0deg);
          }
        }

        &:active {
          background-color: $button-click-plans;
        }
      }

      &.recommended {
        background: linear-gradient(90deg, $gradient-start, $gradient-end);

        .badge {
          align-self: flex-start;
          background-color: $primary;
          color: $light;
          padding: 8px 14px;
          border-radius: 12px;
          margin: 10px 10px 10px auto;
          font-size: 16px;

          @media (max-width: 1024px) {
            font-size: 12px;
            right: auto;
          }
        }
      }
    }

  }

  @media (max-width: 1679px) {
    .container {
      gap: 32px;
    }
  }

  @media (max-width: 1200px) {
    h2 {
      font-size: 36px;
    }

    .container {
      justify-content: flex-start;
      overflow-x: scroll;
      scrollbar-width: none;
      box-shadow: 0 0 10px 0 rgba(2, 54, 54, 0.12);

      .plan__item {
        flex: 0 0 360px;
        box-shadow: 0 0 20px 5px rgba(2, 54, 54, 0.12);

        .plan__item-header {
          h3 {
            font-size: 20px;
          }

          img {
            display: none;
          }
        }

        .plan__item-amount {
          .plan__item-amount-value {
            font-size: 48px;
          }

          .plan__item-amount-period {
            font-size: 24px;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .container {
      gap: 24px;
    }
  }

  @media (max-width: 500px) {
    .container {
      gap: 16px;
    }
  }

  @media (max-width: 480px) {
    h2 {
      font-size: 24px;
      margin-bottom: 0;
    }

    .container {
      flex-wrap: wrap;
      box-shadow: none;
      padding-top: 16px;
      justify-content: center;

      .plan__item {
        box-shadow: 0 0 20px 0 rgba(2, 54, 54, 0.12);

        .plan__item-header h3 {
          padding: 14px 0 0 20px;
        }

        .plan__item-amount {
          margin: 0 20px;
        }

        ul {
          margin: 0 20px 0 37px;
        }

        .plan__btn {
          padding: 14px 20px;
        }
      }
    }
  }
}

.faq_bottom_space {
  margin-bottom: 80px;

  @media (max-width: 480px) {
    margin-bottom: 40px;
  }
}
