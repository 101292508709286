@import '../colors';

.advanced-features {
  background-color: $background-light;

  h2 {
    margin-bottom: 40px;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .advanced-features__items {
    display: flex;
    gap: 20px;

    .advanced-features__item {
      height: 280px;
      background-color: $background-dark;
      border-radius: 12px;
      padding: 24px 32px;
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-10px);
      }

      p {
        height: 40%;
        font-size: 20px;
        font-weight: 600;
        color: $light;
      }

      .step__image {
        display: flex;
        justify-content: center;

        img {
          width: 250px;
          height: 250px;
          overflow: visible;
          position: relative;
          z-index: 1;
        }
      }
    }
  }

  @media (max-width: 1610px) {
    .advanced-features__items {
      flex-wrap: wrap;

      .advanced-features__item {
        flex: 1 1 25%;
      }
    }
  }

  @media (max-width: 768px) {
    .advanced-features__items .advanced-features__item {
      flex: 1 1 170px;

      p {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 480px) {
    margin-bottom: 80px;

    h2 {
      margin-bottom: 16px;
    }

    .advanced-features__items .advanced-features__item {
      height: 210px;
      padding: 14px 20px;

      p {
        height: 30%;
      }

      .step__image {
        img {
          width: 220px;
          height: 220px;
        }
      }
    }
  }
}
