@import '../colors';

@keyframes brightnessChange {
  0%, 100% {
    background-image: url('../../img/home/bg-stage-1.svg');
    filter: opacity(20%);
  }
  50% {
    background-image: url('../../img/home/bg-stage-2.svg');
    filter: opacity(40%);
  }
}

.hero {
  .hero-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 800px;
    text-align: center;
    position: relative;
    padding: 0;

    .animated-background {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      animation: brightnessChange 8000ms linear infinite;
      background-size: cover;
      background-position: bottom;
      background-repeat: no-repeat;
      overflow: visible;
    }

    .hero__content {
      width: 100%;

      img {
        width: 70%;
        margin-top: 80px;
      }

      p {
        color: $text-secondary;
        font-size: 16px;
        margin: 20px auto 80px auto;
        max-width: 70%;
      }

      a {
        font-size: 20px;
        padding: 12px 24px;
        border-width: 2px;
      }
    }

    @media (max-width: 1440px) {
      height: 700px;
    }

    @media (max-width: 1024px) {
      height: 600px;
    }

    @media (max-width: 768px) {
      height: 500px;
    }
  }
}

.steps {
  text-align: center;

  h2 {
    margin-bottom: 0;
  }

  .container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .step__item {
    flex: 1;
    max-width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .step__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .step__header {
        display: flex;

        h3 {
          height: 60px;
          font-size: 24px;
          color: $text-subtitle;
          display: flex;
          align-items: flex-end;
        }
      }

      .step__image {
        width: 240px;
        height: 240px;
        border-radius: 50%;
        box-shadow: 0 0 75px 0 rgba(2, 54, 54, 0.12);
        padding: 30px;
        margin: 10px;
        display: flex;

        img {
          max-width: 240px;
          max-height: 240px;
        }
      }

      p {
        height: 60px;
        font-size: 14px;
        color: $text-description;
      }
    }
  }

  .step__arrow {
    background-color: $text-primary;
    border-radius: 12px;
    padding: 4px;
    height: 40px;
    width: 40px;
    display: flex;
    align-self: center;
  }

  @media (max-width: 1440px) {
    .step__arrow {
      width: 32px;
      height: 32px;
    }
  }

  @media (max-width: 1280px) {
    .step__item {
      max-width: 200px;

      .step__content {
        .step__header h3 {
          font-size: 20px;
          color: $text-subtitle;
        }

        .step__image {
          width: 140px;
          height: 140px;
          margin: 10px 0;

          img {
            max-width: 140px;
            max-height: 140px;
          }
        }
      }
    }

    .step__arrow {
      width: 24px;
      height: 24px;
    }
  }

  @media (max-width: 768px) {
    .step__item {
      min-width: 300px;
    }

    .step__arrow {
      display: none;
    }
  }

  @media (max-width: 480px) {
    .container {
      padding: 0;
    }
  }
}

.trial {
  text-align: center;
  padding-bottom: 40px;

  h2 {
    margin-bottom: 40px;
  }

  a {
    font-size: 20px;
    padding: 12px 24px;
  }

  @media (max-width: 768px) {
    h2 {
      max-width: 80%;
      margin-right: auto;
      margin-left: auto;
    }
  }

  @media (max-width: 480px) {
    h2 {
      font-size: 24px;
      margin-bottom: 32px;
    }

    a {
      font-size: 17px;
      overflow: visible;
      white-space: nowrap;
    }
  }

  @media (max-width: 390px) {
    a {
      font-size: 4vw;
    }
  }
}

.faq {
  h2 {
    margin-bottom: 30px;
  }

  .faq__item {
    padding: 24px 32px;
    background-color: $light;
    border-radius: 12px;
    box-shadow: 0 0 50px 0 $shadow;
    margin-bottom: 10px;
    overflow: hidden;

    .faq__item-question {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 30px;
      font-weight: 700;
      cursor: pointer;

      .faq__item-toggle {
        transition: transform 0.3s ease;
        background-image: url('../../img/icons/plus.svg');
        width: 48px;
        height: 48px;
        background-size: cover;

        &:hover {
          background-image: url('../../img/icons/plus-hover.svg');
        }
      }
    }

    .faq__item-answer {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: max-height 0.5s ease, opacity 0.5s ease, padding 0.5s ease;
      font-size: 20px;

      p {
        margin: 0;
        color: $text-secondary;
      }
    }

    &.active {
      .faq__item-question {
        margin-bottom: 24px;
      }

      .faq__item-answer {
        max-height: 500px;
        opacity: 1;
        padding-top: 24px;
        border-top: 1px solid $divider;
      }
    }

    &.active .faq__item-toggle {
      transform: rotate(-45deg);
    }
  }

  @media (max-width: 730px) {
    .faq__item {
      .faq__item-question {
        font-size: 24px;
        margin: 0;

        .faq__item-text {
          max-width: 85%;
          font-weight: 700;
        }
      }

      .faq__item-answer p {
        font-size: 18px;
      }
    }
  }

  @media (max-width: 480px) {
    margin-top: 40px;

    h2 {
      font-size: 24px;
      margin-bottom: 16px;
    }

    .faq__item {
      padding: 14px 20px;

      .faq__item-question {
        font-size: 18px;
        margin: 0;

        .faq__item-text {
          max-width: 85%;
          font-weight: 700;
        }

        .faq__item-toggle {
          width: 40px;
          height: 40px;
        }
      }

      .faq__item-answer p {
        font-size: 18px;
      }

      &.active .faq__item-answer {
        margin-top: 15px;
        padding-top: 15px;

        p {
          font-size: 14px;
        }
      }
    }
  }
}

.contacts {
  margin-left: 40px;
  margin-right: 40px;

  .container {
    background-color: $background-dark;
    border-radius: 12px;
    color: $light;

    .contacts__title {
      font-size: 48px;
      text-align: center;
      padding-top: 32px;
    }

    .contacts__body {
      display: flex;
      padding: 32px;

      .contacts__info {
        flex: 1;
        display: flex;
        flex-direction: column;

        .contacts__contact_items {
          flex-wrap: wrap;

          .contacts__contact_item {
            margin-bottom: 20px;

            p {
              margin: 10px 12px 0 12px;
              font-size: 18px;
              color: $text-contrast;
            }

            a, span {
              font-size: 36px;
              font-weight: 700;
              display: block;
              color: $light;
              text-decoration: none;
              margin-left: 12px;
            }

            a:hover {
              text-decoration: underline;
            }

            .contacts__contact_item-address {
              font-size: 16px;
              margin-top: 0;
            }
          }
        }

        .contacts__social-icons {
          margin-top: auto;
        }
      }

      .contacts__form {
        flex: 1;
        background-color: $background-light;
        border-radius: 12px;
        padding: 20px;

        form {
          display: flex;
          flex-direction: column;
          gap: 20px;

          textarea {
            resize: none;
            font-family: 'Manrope', serif;
          }

          button {
            align-self: flex-start;
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 20px;
            font-weight: 600;
            padding: 14px 24px;

            img {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    margin-left: 24px;
    margin-right: 24px;

    .container {
      .contacts__title {
        font-size: 36px;
      }

      .contacts__body {
        .contacts__info .contacts__contact_items .contacts__contact_item {
          min-width: 40%;

          p {
            font-size: 16px;
          }

          a, span {
            font-size: 28px;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    margin-left: 24px;
    margin-right: 24px;

    .container {
      .contacts__title {
        font-size: 32px;
        padding-top: 24px;
      }

      .contacts__body {
        flex-direction: column-reverse;
        padding: 24px;

        .contacts__info .contacts__contact_items {
          margin-top: 24px;
          display: flex;

          .contacts__contact_item {
            flex: 1;
          }
        }
      }
    }
  }

  @media (max-width: 660px) {
    .container {
      .contacts__body {
        flex-direction: column-reverse;
        padding: 18px;

        .contacts__info .contacts__contact_items {
          margin-top: 18px;
          display: flex;
          flex-direction: column;

          .contacts__contact_item {
            flex: 1;
          }
        }

        .contacts__form form button {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  @media (max-width: 480px) {
    margin-left: 0;
    margin-right: 0;

    .container {
      border-radius: 0;

      .contacts__title {
        font-size: 24px;
      }
    }
  }
}
