@import '../colors';

.in-dev {
  .container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 20px;

    .in-dev__description {
      font-size: 24px;
      font-weight: 700;
      color: $text-primary;
      margin-top: 20px;
      margin-bottom: 20px;
      max-width: 30%;
    }

    .in-dev__status {
      font-size: 20px;
      color: $text-primary;
      margin-bottom: 20px;
    }

    .in-dev__contact-info {
      display: flex;
      justify-content: space-between;
      max-width: 600px;
      margin: 0 auto;
      background-color: $background-light;
      padding: 20px;
      border-radius: 12px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

      .contact-info__notify-form, .contact-info__contact-details {
        margin: 0 10px;
      }

      .contact-info__notify-form {
        text-align: left;
        padding-right: 20px;
        border-right: 1px solid $divider;
        flex: 1 0 55%;

        p {
          font-size: 16px;
          margin-bottom: 10px;
          color: $text-secondary;
          font-weight: 600;
        }

        .contact-info__email-form {
          display: flex;
          flex-direction: column;
          position: relative;
          gap: 20px;

          input {
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            font-size: 16px;
            outline: none;
            width: 100%;
            box-sizing: border-box;
          }

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            gap: 15px;

            img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }

      .contact-info__contact-details {
        text-align: left;

        p {
          font-size: 16px;
          color: #949E9E;
        }

        a {
          display: block;
          font-size: 18px;
          color: black;
          font-weight: 600;
          text-decoration: none;
          margin-bottom: 10px;

          &:hover {
            text-decoration: underline;
          }
        }

        .contact-info__contact-details_social-icons {
          display: flex;
          gap: 10px;

          a {
            display: inline-block;
            background-color: #005050;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: 1px solid #005050;
            padding: 6px;

            img {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }

    @media (max-width: 1680px) {
      .in-dev__description {
        max-width: 40%;
      }
    }

    @media (max-width: 1024px) {
      .in-dev__description {
        max-width: 60%;
      }
    }

    @media (max-width: 768px) {
      height: 100%;
      padding: 80px 0 0;

      img {
        width: 80%;
      }

      .in-dev__description {
        max-width: 90%;
        font-size: 18px;
      }

      .in-dev__status {
        font-size: 14px;
      }

      .in-dev__contact-info {
        margin: 20px;
        flex-direction: column;

        .contact-info__notify-form {
          border-right: none;

          p {
            font-size: 13px;
          }
        }

        .contact-info__contact-details {
          margin-top: 10px;

          .contact-info__contact-details_social-icons {
            justify-content: center;
          }
        }
      }
    }
  }
}