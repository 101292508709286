@import '../colors';

.reviews {
  .reviews__container {
    position: relative;
    overflow: visible;
    margin: 0 auto;

    .reviews__header {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-top: 20px;
      margin-bottom: 20px;

      .carousel-controls {
        position: absolute;
        right: 0;
        display: flex;
        gap: 16px;
        margin-right: 40px;

        span {
          width: 48px;
          height: 48px;
          background-image: url('../../img/icons/chevron-right.svg');
          background-size: cover;
          transition: fill 0.3s ease;

          &:hover {
            background-image: url('../../img/icons/chevron-right-filled.svg');
          }
        }

        .carousel-controls__prev {
          transform: rotate(180deg);
        }
      }
    }

    .carousel {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      left: -600px;
      position: relative;
      margin: 0 10px;

      .carousel__item {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 600px;
        height: 460px;
        font-weight: bold;
        box-sizing: border-box;
        padding: 24px 32px;
        background-color: $background-light;
        border-radius: 12px;
        box-shadow: 0 0 50px 0 $shadow;
        margin: 0 20px;
        flex-direction: column;
        text-align: left;

        @media (max-width: 1679px) {
          margin: 0 16px;
        }

        @media (max-width: 1024px) {
          margin: 0 12px;
        }

        @media (max-width: 500px) {
          margin: 0 8px;
        }

        blockquote {
          font-size: 36px;
          color: $text-primary;
          margin: 0 0 auto 0;
        }

        .reviews-author {
          display: flex;
          align-items: center;
          padding-top: 20px;
          width: 100%;
          border-top: 1px solid $divider;

          .reviews-author__avatar {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 15px;
          }

          .reviews-author__item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .reviews-author__name {
              font-size: 18px;
              font-weight: bold;
              color: $text-primary;
            }

            .reviews-author__role {
              font-size: 14px;
              color: $text-secondary;
              font-weight: 600;
            }
          }

          .reviews-author__quote-icon {
            margin-left: auto;
            width: 96px;
          }
        }

        @media (max-width: 480px) {
          padding: 14px 20px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .reviews__container .carousel {
      -webkit-overflow-scrolling: touch;
    }
  }

  @media (max-width: 768px) {
    .reviews__container {
      .reviews__header {
        .carousel-controls {
          display: none;
        }
      }

      .carousel .carousel__item {
        width: 300px;
        height: auto;

        blockquote {
          font-size: 20px;
        }

        .reviews-author {
          .reviews-author__avatar {
            width: 40px;
            height: 40px;
          }

          .reviews-author__item {
            .reviews-author__name {
              font-size: 16px;
            }

            .reviews-author__role {
              font-size: 12px;
            }
          }

          .reviews-author__quote-icon {
            width: 48px;
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .reviews__container {
      .reviews__header {
        margin-bottom: 0;
      }
    }
  }
}
