@import "./colors";

.input-block {
  display: block;
  position: relative;

  &__input {
    width: 100%;
    padding: 14px 24px;
    border-radius: 12px;
    border: 1px solid $border-input;
    background-color: $light;
    font-size: 20px;
    font-weight: 500;
    color: $input-text;
    transition: .3s all;
    box-sizing: border-box;

    &:hover {
      border: 1px solid $input-hover;
      background-color: $input-hover;
    }

    &:focus {
      background-color: $light;
      border: 1px solid $input-focus;
    }

    &:disabled {
      background-color: $select-bg;
    }
  }

  .input-block__error {
    display: none;
  }

  &.error {
    .input-block__input {
      border: 1px solid $input-error;
    }

    .input-block__error {
      display: block;
      color: $input-error;
      position: absolute;
      top: 100%;
      left: 0;
    }
  }
}
