@import './header.scss';
@import './footer.scss';
@import './colors.scss';
@import './inputs.scss';
@import './views/home.scss';
@import './views/in-dev.scss';
@import './views/features.scss';
@import './views/plans.scss';
@import './views/about.scss';
@import './components/features.scss';
@import './components/head-panel.scss';
@import './components/social-panel.scss';
@import './components/reviews.scss';

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-family: 'Manrope', serif;
  overflow-x: hidden;
  margin: 0;
  background: $background-body;
}

:root {
  font-size: 10px;
}

h1, h2, h3, h4, h5, h6, p, ul, li {
  margin: 0;
  padding: 0;
}

.container {
  width: 100%;
  max-width: 1680px;
  margin: 0 auto;
}

.section__side-space {
  margin-left: 40px;
  margin-right: 40px;

  @media (max-width: 1679px) {
    margin-left: 32px;
    margin-right: 32px;
  }

  @media (max-width: 1024px) {
    margin-left: 24px;
    margin-right: 24px;
  }

  @media (max-width: 500px) {
    margin-left: 16px;
    margin-right: 16px;
  }
}

.section__top-space {
  margin-top: 160px;

  @media (max-width: 1024px) {
    margin-top: 100px;
  }

  @media (max-width: 480px) {
    margin-top: 40px;
  }
}

.default-button {
  font-size: 16px;
  font-weight: 500;
  background-color: transparent;
  padding: 8px 16px;
  border: 2px solid transparent;
  border-radius: 12px;
  text-decoration: none;
  color: $button-main;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;

  &:hover {
    background-color: $button-hover;
    border-color: $button-hover;
  }

  &:focus {
    background-color: $button-main;
    border-color: $button-main;
    color: $button-background;
  }

  &:focus-visible {
    background-color: $button-main;
    border-color: $button-main;
    color: $button-background;
  }

  &:disabled {
    border-color: $button-disabled;
  }

  &.active {
    background-color: $button-main;
    border-color: $button-main;
    color: $button-background;
  }

  &.filled {
    background-color: $button-main;
    color: $button-background;

    &:disabled {
      background-color: $button-disabled;
      border-color: $button-disabled;
    }

    &:hover {
      background-color: $button-background;
      color: $button-main;
      border-color: $button-main;

      img {
        filter: brightness(0) saturate(100%) invert(30%) sepia(94%) saturate(2608%) hue-rotate(165deg) brightness(87%) contrast(88%);
      }
    }
  }
}

.section-header {
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  color: $text-primary;
  margin-bottom: 10px;

  @media (max-width: 1024px) {
    font-size: 36px;
  }

  @media (max-width: 768px) {
    font-size: 32px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
    margin-bottom: 16px;
  }
}