@import './colors.scss';

.header {
  position: fixed;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s, backdrop-filter 0.3s;

  &.transparent {
    background-color: transparent;
    backdrop-filter: none;
  }

  &.scrolled {
    background-color: $background-blur;
    backdrop-filter: blur(10px);
  }

  .container {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    transition: padding 0.3s;

    .header__left {
      padding-left: 32px;
      display: flex;
      align-items: center;
      gap: 20px;

      .header__left-logo img {
        height: 40px;
      }

      .header__left-nav ul {
        list-style: none;
        display: flex;
        gap: 10px;

        li a {
          border: none;

          &:hover {
            border: none;
          }
        }
      }
    }

    .header__right {
      padding-right: 32px;
      max-height: 51px;
      display: flex;
      align-items: center;
      gap: 20px;

      .lang-toggle {
        height: 100%;
      }

      .header__right-login {
        font-size: 20px;
        font-weight: 600;
        padding: 12px 24px;
      }

      .header__right-menu {
        display: none;
      }
    }
  }

  .mobile-menu {
    display: none;
  }

  @media (max-width: 1024px) {
    .container {
      padding: 12px 0;

      .header__left {
        margin-left: 24px;
        padding-left: 0;
      }

      .header__right {
        margin-right: 24px;
        padding-right: 0;
      }
    }
  }

  @media (max-width: 768px) {
    .container {
      padding: 12px 0;
      background-color: $light;
      z-index: 1002;
      position: fixed;

      .header__left {
        padding-left: 0;

        .header__left-nav {
          display: none;
        }
      }

      .header__right {
        display: flex;
        align-items: center;
        gap: 10px;

        .header__right-login {
          display: none;
        }

        .header__right-menu {
          display: flex;
          flex-direction: column;
          width: 24px;
          height: 24px;
          cursor: pointer;
          position: relative;
          align-items: flex-end;
          background-color: $primary;
          border-radius: 12px;
          gap: 6px;
          justify-content: center;
          padding: 13.5px;

          .line {
            display: block;
            width: 100%;
            height: 2px;
            border-radius: 1px;
            background-color: $light;
            transition: transform 0.3s ease, opacity 0.3s ease;

            &:nth-child(1) {
              width: 100%;
              transform-origin: bottom right;
            }

            &:nth-child(2) {
              width: 90%;
              transform-origin: center;
            }

            &:nth-child(3) {
              width: 80%;
              transform-origin: top right;
            }
          }

          &.active {
            gap: 8px;

            .line1 {
              margin-top: 0;
              transform: rotate(-23.5deg);
              width: 25.5px;
            }

            .line2 {
              display: none;
            }

            .line3 {
              transform: rotate(23.5deg);
              width: 25.5px;
            }
          }
        }

        .mobile-menu {
          transition: transform 0.3s ease;
          transform: translateY(-100%);

          &.active {
            transform: translateY(0);
          }
        }
      }
    }

    &.scrolled {
      background-color: $light;
    }

    .mobile-menu {
      display: none;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: $background-light;
      z-index: 1001;
      opacity: 0;
      transition: opacity 0.5s ease, transform 0.5s ease;
      transform: translateY(-100%);
      margin-top: 75px;

      &.active {
        display: flex;
        opacity: 1;
        transform: translateY(0);
      }

      .mobile-menu__body {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 620px;

        .mobile-menu__social-links {
          margin-top: 24px;
        }

        .mobile-nav {
          ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 36px;

            li {
              text-align: center;

              a {
                font-size: 20px;
                padding: 12px 20px;
              }
            }
          }
        }

        .mobile-menu__login {
          font-size: 24px;
          padding: 12px 20px;
        }
      }
    }
  }
}