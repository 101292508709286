@import '../colors.scss';

.social-panel {
  display: flex;
  gap: 24px;
  align-items: center;

  a {
    border-radius: 12px;
    width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    margin-left: 0;
    background-color: $button-main;

    img {
      width: 48px;
      height: 48px;
    }

    &:hover {
      background-color: $light;

      img {
        filter: brightness(0) saturate(100%) invert(30%) sepia(94%) saturate(2608%) hue-rotate(165deg) brightness(87%) contrast(88%);
      }
    }
  }
}