@import '../colors.scss';

.head-panel {
  .container {
    display: flex;
    text-align: center;
    flex-direction: column;

    h1 {
      font-size: 128px;
      font-weight: 600;
      color: $primary;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      color: $text-secondary;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    @media (max-width: 768px) {
      h1 {
        font-size: 96px;
      }
    }

    @media (max-width: 480px) {
      padding-top: 75px;

      h1 {
        font-size: 52px;
      }

      p {
        font-size: 14px;
      }
    }
  }
}