@import './colors.scss';

.footer {
  margin: 80px 40px 20px 40px;
  color: $text-light;
  position: relative;
  bottom: 0;

  .container {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 768px){
      flex-direction: column;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__left-link {
    .item {
      height: 24px;
      object-fit: contain;
      margin-right: 8px;
      filter: grayscale(100%);
      opacity: .8;
      transition: .3s all;
      &:hover {
        filter: grayscale(0%);
        opacity: 1;
      }
    }
  }

  &__left-copyright {
    font-size: 1.4rem;
  }

  &__right {
    text-align: right;
    @media screen and (max-width: 768px){
      text-align: left;
      margin: 8px 0;
    }
  }

  &__right-doc {
    font-size: 1.6rem;
    line-height: 140%;
    color: $text-light;
    transition: .3s all;
    cursor: pointer;
    margin: 4px 0;
    &:hover {
      color: $text-primary;
    }
  }

  &__copyright {
    font-size: 1.4rem;
  }

  .desktop {
    display: block;
    @media screen and (max-width: 768px){
      display: none;
    }
  }

  .mobile {
    display: none;
    @media screen and (max-width: 768px){
      display: block;
    }
  }
}


.modal-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.60);
  backdrop-filter: blur(15px);
  opacity: 0;
  transition: .3s all;
  z-index: -1;

  &.active {
    opacity: 1;
    z-index: 1000;
  }

  .container {
    position: relative;
  }
}

.modal {
  width: calc(100vw - 80px);
  max-width: 1440px;
  max-height: calc(100vh - 80px);
  overflow: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 2000;
  transform: translate(-50%, -50%) scale(0);
  padding: 24px;
  border-radius: 12px;
  background-color: $light;
  box-shadow: 0 0 50px 0 rgba(2, 54, 54, 0.12);
  transition: .3s all;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 525px) {
    padding: 24px 16px;
  }

  &__close-btn {
    width: 51px;
    height: 51px;
    flex-shrink: 0;
    border: none;
    background-color: $background-dark;
    border-radius: 12px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
      display: block;
    }
  }

  &__body {
    width: 100%;
    height: calc(100% - 120px);
    overflow: hidden;
    overflow-y: auto;
  }

  &__body-content {
    font-size: 1.4rem;
    h1 {
      font-size: 1.8rem;
      margin-bottom: 8px;
    }
    h2 {
      font-size: 1.4rem;
      margin-bottom: 4px;
    }
    p {
      margin-bottom: 4px;
    }
  }

  &.active {
    transform: translate(-50%, -50%) scale(1);
  }
}