@import '../colors.scss';

.mission {
  .container {
    text-align: center;

    p {
      font-size: 24px;
      font-weight: 500;
      color: $text-secondary;
      text-align: start;

      @media (max-width: 1024px) {
        font-size: 18px;
      }

      @media (max-width: 500px) {
        font-size: 14px;
      }
    }
  }
}

.switch-board {
  .container {
    .switch-board__container {
      margin-top: 40px;
      display: flex;
      background-color: $primary;
      border-radius: 12px;
      padding: 24px 32px;
      justify-content: space-between;
      height: 125px;
      overflow: hidden;

      .switch-board__item-body {
        display: flex;

        .switch-board__item {
          display: none;
          opacity: 0;
          transform: translateY(100%);
          transition: opacity 0.3s ease, transform 0.3s ease;
          flex-direction: row;
          align-items: center;

          h2 {
            font-weight: 600;
            font-size: 96px;
            color: $light;
            padding-right: 40px;
            border-right: 1px solid $divider-switcher;
            width: 250px;
          }

          p {
            flex: 1;
            font-weight: 600;
            font-size: 24px;
            color: $light;
            padding-left: 40px;
          }
        }
      }

      .switch-board__item-controls {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .switch-board__btn {
          width: 48px;
          height: 48px;
          background-image: url('../../img/icons/chevron.svg');
          background-size: cover;
          transition: fill 0.3s ease;

          &:hover {
            background-image: url('../../img/icons/chevron-filled.svg');
          }
        }

        .switch-board__btn-down {
          transform: rotate(180deg);
        }
      }

      @media (max-width: 700px) {
        margin-top: 0;
        flex-direction: column;
        height: 400px;
        padding: 24px;

        .switch-board__item-body .switch-board__item {
          flex-direction: column;
          align-items: start;
          transform: translateX(-100%);

          h2 {
            border: none;
            padding: 0;
          }

          p {
            margin-top: 24px;
            font-size: 20px;
            padding: 0;
          }
        }

        .switch-board__item-controls {
          margin-top: 10px;
          flex-direction: row;
          justify-content: space-between;

          .switch-board__btn-up {
            transform: rotate(-90deg);
          }

          .switch-board__btn-down {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}

.team {
  .container {
    text-align: center;

    .team__container {
      margin-top: 40px;
      display: flex;
      gap: 100px;
      justify-content: center;
      flex-wrap: wrap;

      .team__item {
        img {
          border-radius: 50%;
          width: 300px;
          height: 300px;
        }

        h5 {
          margin-top: 20px;
          font-size: 24px;
          font-weight: 700;
        }

        p {
          margin-top: 6px;
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .container .team__container {
      gap: 70px;

      .team__item {
        img {
          width: 270px;
          height: 270px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .container .team__container {
      gap: 30px;

      .team__item {
        img {
          width: 220px;
          height: 220px;
        }

        h5 {
          font-size: 20px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .container .team__container {
      gap: 10px;

      .team__item {
        img {
          width: 170px;
          height: 170px;
        }
      }
    }
  }
}

.values {
  background-color: $background-body;

  h2 {
    margin-bottom: 100px;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .values__items {
      display: flex;
      gap: 40px;
      flex-wrap: wrap;

      @media (max-width: 1679px) {
        gap: 32px;
      }

      @media (max-width: 1024px) {
        gap: 24px;
      }

      @media (max-width: 500px) {
        gap: 16px;
      }

      .values__item {
        height: 240px;
        flex: 1 1 15%;
        background-color: $light;
        border-radius: 12px;
        transition: transform 0.3s ease;
        box-shadow: 0 0 50px 0 $shadow;
        padding: 24px 32px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        &:hover {
          transform: translateY(-10px);
        }

        .step__image {
          display: flex;
          justify-content: center;
          position: relative;

          img {
            width: 300px;
            height: 300px;
            position: relative;
            top: 10%;
          }
        }

        h5 {
          font-size: 24px;
          font-weight: 700;
          color: $text-primary;
        }

        p {
          color: $text-secondary;
          font-size: 14px;
          font-weight: 500;
          margin-top: 4px;
        }
      }
    }
  }

  @media (max-width: 1580px) {
    .container .values__items .values__item {
      min-width: 40%;
      flex: 1 1 25%;
    }
  }

  @media (max-width: 768px) {
    .container .values__items .values__item {
      flex: 1 1 25%;

      .step__image {
        img {
          width: 270px;
          height: 270px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    h2 {
      margin-bottom: 40px;
    }

    .container .values__items .values__item {
      padding: 14px 20px;
    }
  }
}

