@import "../colors.scss";

.features {
  text-align: center;

  .container {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
    margin-top: 40px;
    padding: 0;
  }

  .features__item {
    flex: 1;
    min-width: 24%;
    max-width: 31%;
    display: flex;
    flex-direction: column;
    background-color: $background-light;
    border-radius: 12px;
    box-shadow: 0 0 50px 0 $shadow;
    padding: 24px 32px;
    transition: transform 0.3s ease;
    text-align: left;

    &:hover {
      transform: translateY(-10px);
    }

    .features__header {
      margin-bottom: 10px;

      h2 {
        font-size: 24px;
        color: $text-primary;
        margin: 0;
      }
    }

    .features__content {
      display: flex;
      flex-direction: row;
      gap: 20px;

      .features__description {
        flex: 3;

        p {
          font-size: 16px;
          color: $text-secondary;
          margin: 0;
        }
      }

      .features__image {
        flex: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
          max-width: 100%;
          height: auto;
          position: relative;
        }
      }
    }
  }

  @media (max-width: 1679px) {
    .container {
      gap: 32px;
    }
  }

  @media (max-width: 1440px) {
    .features__item {
      min-width: 29%;
    }
  }

  @media (max-width: 1024px) {
    .container {
      gap: 24px;
    }

    .features__item {
      min-width: 40%;
    }
  }

  @media (max-width: 768px) {
    .features__item {

      .features__content {
        flex-direction: column;

        .features__image img {
          max-width: 80%;
        }
      }
    }
  }

  @media (max-width: 660px) {
    .container {
      gap: 16px;
      margin-top: 0;
    }

    .features__item {
      min-width: 90%;
      padding: 14px 20px;
    }
  }
}